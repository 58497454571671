<x-data-table [view]="view">
  <x-order-pill
    *xDataColumnDef="'number'; let row"
    [order]="row"
    [matMenuTriggerFor]="rowOrderMenu"
    [matMenuTriggerData]="{ $implicit: row }"
  ></x-order-pill>

  <mat-menu #rowOrderMenu>
    <ng-template matMenuContent let-order>
      <a mat-menu-item [routerLink]="['/admin/orders', order.id, 'detail']">View Order</a>
    </ng-template>
  </mat-menu>

  <x-address-pill
    *xDataColumnDef="'shippingAddress'; let row"
    [address]="row.shippingAddress"
    [matMenuTriggerFor]="rowShippingAddressMenu"
    [matMenuTriggerData]="{ $implicit: row }"
  ></x-address-pill>

  <mat-menu #rowShippingAddressMenu>
    <ng-template matMenuContent let-order>
      <a mat-menu-item (click)="updateShippingAddress(order.id, order.shippingAddress)">
        Update Shipping Address
      </a>
    </ng-template>
  </mat-menu>

  <x-geo-region-pill
    *xDataColumnDef="'geoRegion'; let row"
    [region]="row.shippingAddressGeoRegion"
  ></x-geo-region-pill>

  <x-pill *xDataColumnDef="'collectionPoint'; let row">{{ row.collectionPoint?.name }}</x-pill>

  <ng-container *xDataColumnDef="'user'; let row">
    <x-user-pill
      *ngIf="row.user"
      [user]="row.user"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.user }"
    ></x-user-pill>
  </ng-container>

  <mat-menu #rowUserMenu>
    <ng-template matMenuContent let-user>
      <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
    </ng-template>
  </mat-menu>

  <x-channel-pill *xDataColumnDef="'channel'; let row" [channel]="row.channel"></x-channel-pill>

  <span *xDataColumnDef="'checkoutAt'; let row" class="mat-subtitle-2" [title]="row.checkoutAt">
    <x-datetime [datetime]="row.checkoutAt" format="short"></x-datetime>
  </span>

  <ng-container *xDataColumnDef="'tags'; let row">
    <x-pill *ngIf="row.isGift" text="Gift" icon="gift"></x-pill>
    <x-pill
      *ngIf="row.printedAt"
      text="Printed"
      icon="print"
      [matTooltip]="(row.printedAt | datetime) ?? 'Order Printed'"
    ></x-pill>
    <x-pill
      *ngIf="row.shipment?.labeledAt"
      text="Waybill"
      icon="box"
      [matTooltip]="(row.shipment?.labeledAt | datetime) ?? 'Waybill Printed'"
    ></x-pill>
    <x-order-tag-pill *ngFor="let tag of row.tags" [tag]="tag"></x-order-tag-pill>
  </ng-container>

  <x-order-payment-pill *xDataColumnDef="'payment'; let row" [order]="row"></x-order-payment-pill>

  <x-fulfilment-pill
    *xDataColumnDef="'fulfilment'; let row"
    [fulfilment]="row.fulfilment"
  ></x-fulfilment-pill>

  <x-shipment-pill
    *xDataColumnDef="'shipment'; let row"
    [shipment]="row.shipment"
  ></x-shipment-pill>

  <ng-container *xDataColumnDef="'route'; let row">
    <div class="x-pill-base">
      <div class="pill-body">
        <div class="pill-label">
          {{ row.shipment.driver ?? 'Unassigned' }} / {{ row.shipment.routeSequence }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="mat-subtitle-2" *xDataColumnDef="'total'; let row">
    {{ row.total | money: row.currency }}
  </div>

  <ng-container class="mat-subtitle-2" *xDataColumnDef="'parcels'; let row">
    <table style="width: 300px">
      <tbody>
        <tr *ngFor="let parcel of row.shipment?.parcels">
          <th width="150">{{ parcel.name }}</th>
          <td width="150">
            <span>{{ parcel.weight }}g</span>
            <br />
            <span class="mat-hint">
              {{ parcel.dimensions?.width }}
              x {{ parcel.dimensions?.height }} x {{ parcel.dimensions?.length }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *xDataColumnDef="'bulkOrderEntry'; let row">
    <x-bulk-order-entry-pill
      *ngIf="row.bulkOrderEntry"
      [entry]="row.bulkOrderEntry"
    ></x-bulk-order-entry-pill>
  </ng-container>

  <a
    x-data-button
    *xDataAction="'detail'; let row; primary: true"
    [routerLink]="['/admin/orders', row.id, 'detail']"
    label="View Order"
    icon="search"
  ></a>

  <button
    x-data-button
    *xDataAction="'assignCustomer'; let row"
    label="Assign Customer"
    icon="user"
    (click)="assignCustomer(row.id)"
  ></button>

  <button
    x-data-button
    *xDataAction="'order'; let row"
    label="Update Shipping Address"
    icon="map"
    (click)="updateShippingAddress(row.id, row.shippingAddress)"
  ></button>

  <button
    x-data-button
    *xDataAction="'user'; let row"
    label="Add Parcel"
    icon="box"
    color="primary"
    (click)="createParcel(row.id)"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'user'; let ids"
    label="Order"
    icon="clipboard-list"
    color="primary"
    [matMenuTriggerFor]="ordersMenu"
  ></button>

  <mat-menu #ordersMenu>
    <button mat-menu-item (click)="cancelBulk()">Cancel Order(s)</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'user'; let ids"
    label="Customer"
    icon="user"
    color="primary"
    [matMenuTriggerFor]="userMenu"
  ></button>

  <mat-menu #userMenu>
    <button mat-menu-item (click)="assignCustomerBulk()">Assign Customer</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'fulfilment'; let ids"
    label="Fulfilment"
    icon="cart-flatbed"
    color="primary"
    [matMenuTriggerFor]="fulfilmentMenu"
  ></button>

  <mat-menu #fulfilmentMenu>
    <button mat-menu-item (click)="unallocateFulfilmentBulk()">Unallocate</button>
    <button mat-menu-item (click)="allocateFulfilmentBulk()">Allocate</button>
    <button mat-menu-item (click)="unpackFulfilmentBulk()">Unpack</button>
    <button mat-menu-item (click)="packFulfilmentBulk()">Pack</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'checkout'; let ids"
    label="Checkout"
    icon="cart-flatbed"
    color="primary"
    [matMenuTriggerFor]="checkoutMenu"
  ></button>

  <mat-menu #checkoutMenu>
    <button mat-menu-item (click)="checkout()">Checkout</button>
    <button mat-menu-item (click)="manualPayment()">Manual Payment</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'shipment'; let ids"
    label="Shipment"
    icon="truck"
    color="primary"
    [matMenuTriggerFor]="shipmentMenu"
  ></button>

  <mat-menu #shipmentMenu>
    <button mat-menu-item (click)="submitWaybillBulk()">Submit Waybill</button>
    <button mat-menu-item (click)="shipShipmentBulk()">Ship</button>
    <button mat-menu-item (click)="deliverShipmentBulk()">Deliver</button>
    <button mat-menu-item (click)="reconShipmentsBulk()">Recon</button>
    <button mat-menu-item (click)="openFulfillmentIntervalDialog()">
      Assign Shipping Slot (Carts)
    </button>
    <button mat-menu-item (click)="cancelShipmentBulk()">Cancel Shipment</button>

    <button
      mat-menu-item
      (click)="openShipmentFormDialog()"
      [disabled]="!authContext.currentUserHasPermissions(['order_reschedule'])"
    >
      Reschedule
    </button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'print'; let ids"
    label="Print"
    icon="print"
    color="primary"
    [matMenuTriggerFor]="printMenu"
  ></button>

  <mat-menu #printMenu>
    <button mat-menu-item (click)="printManifestBulk()">Order Manifest</button>
    <button mat-menu-item (click)="printTaxInvoiceBulk()">Tax Invoice</button>
    <button mat-menu-item (click)="printShipmentLabelBulk()">Shipment Label</button>
    <button mat-menu-item (click)="printRouteManifestBulk()">Route Manifest</button>
    <button mat-menu-item (click)="printRouteSummaryBulk()">Route Summary</button>
    <button mat-menu-item (click)="printOrderGiftMessageBulk()">Gift Messages</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'tag'; let ids"
    label="Tag"
    icon="tag"
    color="primary"
    [matMenuTriggerFor]="tagMenu"
  ></button>

  <mat-menu #tagMenu>
    <button mat-menu-item (click)="updateTagsBulk(true)">Add Tag(s)</button>
    <button mat-menu-item (click)="updateTagsBulk(false)">Remove Tag(s)</button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'notify'; let ids"
    label="Notify"
    icon="envelope"
    color="primary"
    [matMenuTriggerFor]="notifyMenu"
  ></button>

  <mat-menu #notifyMenu>
    <button mat-menu-item (click)="sendBulkEmail('orderShippedEmail')">
      Send Order Shipped Email
    </button>
    <button mat-menu-item (click)="sendBulkEmail('requestPaymentEmail')">
      Send Request Payment Email
    </button>
  </mat-menu>

  <button
    x-data-button
    *xDataBulkAction="'export'; let ids"
    label="Export"
    icon="file-arrow-down"
    color="primary"
    [matMenuTriggerFor]="exportMenu"
  ></button>

  <mat-menu #exportMenu>
    <button mat-menu-item (click)="exportCsvManifest()">CSV Manifest - Own Delivery</button>
    <button mat-menu-item (click)="exportCourierSpreadsheet('csv')">
      Manifest - Courier [.csv]
    </button>
    <button mat-menu-item (click)="exportCourierSpreadsheet('xlsx')">
      Manifest - Courier [.xlsx]
    </button>
    <button mat-menu-item (click)="exportSelected('csv')">Order Export [.csv]</button>
    <button mat-menu-item (click)="exportSelected('xlsx')">Order Export [.xlsx]</button>
    <button mat-menu-item (click)="exportRoyalMailSpreadsheet('csv')">
      Royal Mail Export [.csv]
    </button>
    <button mat-menu-item (click)="exportPackFleetSpreadsheet('csv')">
      Export PackFleet [.csv]
    </button>
  </mat-menu>
</x-data-table>
